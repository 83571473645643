import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentTitle, IconTextValue, LinkButton, useLang } from '@shapeable/ui';
import { Link } from 'gatsby';
import { classNames } from '@shapeable/utils';
import { ExternalLinkIcon, ExternalLinkIconGlyph, SiteIconGlyph } from '@shapeable/icons';
const cls = classNames('article-cta');

// -------- Types -------->

export type ArticleCtaProps = Classable & HasChildren & {
  url?: string;
  websiteName?: string;
  websiteUrl?: string;
}

export const ArticleCtaDefaultProps: ArticleCtaProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    gap: ${theme.UNIT(5)};
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  `,
});

const ButtonStyles = breakpoints({
  base: css`
    width: 100%;
    max-width: 300px;
    min-width: 50px;
  `,
});

const WebsiteValueStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Button: styled(LinkButton)`${ButtonStyles}`,
    WebsiteValue: styled(IconTextValue)`${WebsiteValueStyles}`,
};

export const ArticleCta: Shapeable.FC<ArticleCtaProps> = (props) => {
  const { className, url, websiteName, websiteUrl } = props;

  const t = useLang();

  const externalicon = ExternalLinkIconGlyph;

  return (
    <My.Container className={cls.name(className)}>
      <My.Button href={url} icon={externalicon} shape='rounded'>{t('Read Full Article')}</My.Button>
      <My.WebsiteValue href={websiteUrl} color='primary' iconSize={40} iconComponent={SiteIconGlyph} label='Website'>{websiteName}</My.WebsiteValue>
    </My.Container>
  )
};

ArticleCta.cls = cls;
ArticleCta.defaultProps = ArticleCtaDefaultProps;