import { graphql } from 'gatsby';
import { ArticleLayout } from '../../components/entities/article-layout';
import { createGatsbyPageComponent } from '@shapeable/ui';

export default createGatsbyPageComponent('FeedEntry', { layout: ArticleLayout, debug: false });

export const QUERY = graphql`
  query feedEntryQuery($id: ID!) {
    platform {
      feedEntry(id: $id) {
        id path slug name url published author 
        __typename _schema { label pluralLabel }
        summary { id text }
        locations { id name path }
        websiteUrl
        websiteName
        openGraph { description { text(truncate: { words: 20 }) } image { url url2x thumbnails { card { url url2x } bubble { url url2x } } } }
        challenge { id name slug __typename }
        species { 
          id name slug __typename _schema { label pluralLabel } 
          openGraph { id image { id url url2x } } 
        }
        organisations { id name slug __typename _schema { label pluralLabel } }
        methodologies { id name slug __typename _schema { label pluralLabel } }
        people {
          id name slug path __typename
          organisation { id name }
          linkedin
          position
          photo { id url url2x type }
        }
      }
    }
  }`;
