import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, FeedEntry } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity } from '@shapeable/ui';
import { ArticleBannerLayout } from './article-banner-layout';
import { classNames } from '@shapeable/utils';
const cls = classNames('article-layout');

// -------- Types -------->

export type ArticleLayoutProps = Classable & HasChildren & { 
  entity?: FeedEntry;
};

export const ArticleLayoutDefaultProps: Omit<ArticleLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});


// -------- Components -------->

const My = {
  Layout: styled(ArticleBannerLayout)<ContainerProps>`${ContainerStyles}`,
};

export const ArticleLayout: Shapeable.FC<ArticleLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  return (
   <My.Layout className={cls.name(className)} entity={entity} />
  )
};

ArticleLayout.defaultProps = ArticleLayoutDefaultProps;
ArticleLayout.cls = cls;