import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Page } from '@shapeable/types';
import { FeedEntry } from '@shapeable/kelp-forest-alliance-types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellHeader, ContentNode, ContentTitle, EntityMultiValueList, EntityValuePill, FeedEntryDetailsHeader, MainAside, PageHeader, PageHeaderProps, SliceLayoutBoundary, useLang } from '@shapeable/ui';
import { ArticleCta } from '../elements/article-cta';
import { DARK_BLUE, OCEAN_BLUE, OCEAN_TIEL, ORANGE, PRIMARY_BLUE } from '../../theme';
import { classNames } from '@shapeable/utils';
const cls = classNames('article-banner-layout');

// -------- Types -------->

export type ArticleBannerLayoutProps = Classable & HasChildren & { 
  entity?: FeedEntry;
  headerProps?: Partial<PageHeaderProps>;
  bannerComponent?: React.FC;
};

export const ArticleBannerLayoutDefaultProps: Omit<ArticleBannerLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    h1.shp--content-title {
      font-size: 1.2em;
      text-transform: none;
      margin-bottom: ${theme.UNIT(2)};
    }
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});

const BannerdetailsStyles = breakpoints({
  base: css`
    
  `,
});



const BodyStyles = breakpoints({
  base: css`
    width: 100%;
    margin-bottom: ${theme.UNIT(6)};
  `,
});

const MainAsideStyles = breakpoints({
  base: css`
    padding: 0;
  `,
  tablet: css`
    padding: ${theme.UNIT(4)} 0;
  `,
});

const AsideStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(6)};
  `,
  desktop: css`
    padding-left: 0;
    padding-right: 0;
  `,
});

const AsideItemStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(4)};

  `,
});

const SummaryStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(6)} ${theme.UNIT(0)} ${theme.UNIT(7)};
    ${theme.H_DOT_BG_END()};
    margin-bottom: ${theme.UNIT(4)};
  `,
});

const CtaTitleStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(6)};
    padding-bottom: ${theme.UNIT(4)};
  `,
});

const ArticleCtaStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(4)};
  `,
  desktop: css`
    margin-bottom: 0;
  `
});

const PeopleStyles = breakpoints({
  base: css`
    ${AsideItemStyles};
  `,
});

const OrganisationsStyles = breakpoints({
  base: css`
    ${AsideItemStyles};
  `,
});

const MotivationsContainerStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
    margin-right: ${theme.UNIT(2)};
  `,
});

const MotivationsHeaderStyles = breakpoints({
  base: css`
    
  `,
});

const MotivationsStyles = breakpoints({
  base: css`
   
    margin-top: ${theme.UNIT(2)};
  `,
});

const MethodologiesStyles = breakpoints({
  base: css`
    ${AsideItemStyles};
  `,
});

const SpeciesStyles = breakpoints({
  base: css`
    ${AsideItemStyles};
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(PageHeader).attrs(cls.attr('header'))`${HeaderStyles}`,
    BannerDetails: styled(FeedEntryDetailsHeader)`${BannerdetailsStyles}`,
    Body: styled.div`${BodyStyles}`,

    MainAside: styled(MainAside)`${MainAsideStyles}`,
      Summary: styled(ContentNode)`${SummaryStyles}`,
      CtaTitle: styled(ContentTitle)`${CtaTitleStyles}`,
      ArticleCta: styled(ArticleCta)`${ArticleCtaStyles}`,

    Aside: styled.div`${AsideStyles}`,
      People: styled(EntityMultiValueList)`${PeopleStyles}`,
      Organisations: styled(EntityMultiValueList)`${OrganisationsStyles}`, 
      MotivationsContainer: styled.div`${MotivationsContainerStyles}`,
        MotivationsHeader: styled(CellHeader)`${MotivationsHeaderStyles}`,
        Motivations: styled(EntityValuePill)`${MotivationsStyles}`,
      Methodologies: styled(EntityMultiValueList)`${MethodologiesStyles}`,
      Species: styled(EntityMultiValueList)`${SpeciesStyles}`,
};

export const ArticleBannerLayout: Shapeable.FC<ArticleBannerLayoutProps> = (props) => {
  const { className, children, headerProps, entity, bannerComponent } = props;

  const { summary, name, url, websiteName, websiteUrl, challenge, people = [], organisations = [],
  species = [], methodologies = [], types = [] } = entity;

  const hasPeople = !!people.length;
  const hasOrganisations = !!organisations.length;
  const hasSpecies = !!species.length;
  const hasMethodologies = !!methodologies.length;
  const hasMotivations = !!challenge;

  const t = useLang();

  const page: Page = {
    // ...entity,
    title: entity.name,
    banners: entity.banners,
  };

  if (!page.banners?.length) {
    page.banners = page.imageAssets || []
  }

  return (
   <My.Container className={cls.name(className)}>
      <My.Header useEntityColors entity={page} {...headerProps} variant='overlay' backgroundColor={PRIMARY_BLUE} fadeColor='#0000000'>
      <My.BannerDetails entity={entity} color='#FFFFFF' badgeColor={ORANGE}/>
    </My.Header>

    <SliceLayoutBoundary>
      <My.Body>
        <My.MainAside
          aside={
            <My.Aside>
              {
                hasPeople &&
                  <My.People items={people} />
              }
              {
                hasOrganisations &&
                  <My.Organisations items={organisations} />
              }
              {
                hasMotivations && 
                  <My.MotivationsContainer>
                    <My.MotivationsHeader>{t('Motivations:')}</My.MotivationsHeader>
                    <My.Motivations variant='tiny' entity={challenge} />
                  </My.MotivationsContainer>
              }
              {
                hasSpecies &&
                  <My.Species items={species}  />
              }
              {
                hasMethodologies &&
                  <My.Methodologies items={methodologies} />
              }
            </My.Aside>
          }>
          <My.Summary title={t('Summary:')} entity={summary} />
          <My.CtaTitle>{t('Continue Reading:')}</My.CtaTitle>
          <My.ArticleCta url={url} websiteName={websiteName} websiteUrl={websiteUrl}/>
        </My.MainAside>
      </My.Body>

    </SliceLayoutBoundary>
   {children}
   </My.Container>
  )
};

ArticleBannerLayout.defaultProps = ArticleBannerLayoutDefaultProps;
ArticleBannerLayout.cls = cls;